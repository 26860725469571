/** @jsx jsx */
import { jsx, Flex } from "theme-ui"

const defaultProps = {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
}

const Row = ({children, flexDirection, width, alignItems, justifyContent}) => (
    <Flex sx={{
        flexDirection,
        width,
        alignItems,
        justifyContent,
        '@media only screen and (min-device-width: 375px) and (max-device-width: 667px)': {
            flexWrap: "wrap",
        },
    }}>
        {children}
    </Flex>
)

export default Row

Row.defaultProps = defaultProps