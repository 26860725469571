/** @jsx jsx */
import { jsx, Grid, Heading, Image, Flex, Text, Link } from "theme-ui"
import { BsArrowRight } from 'react-icons/bs'
import Divider from "../elements/divider"
import Inner from "../elements/inner"
import Content from "../elements/content"
import SVG from "./svg"
import BrushSvg from "./brush-svg"
import { UpDown, UpDownWide } from "../styles/animations"
// @ts-ignore
import ProjectsMDX from "../sections/projects"
import Row from "./Row"
import ArrowRight from "./arrow-right"

const Projects = ({ offset, factor = 2 }: { offset: number; factor?: number }) => (
  <div>
    <Divider
      bg="divider"
      sx={{ clipPath: `polygon(0 15%, 100% 25%, 100% 85%, 0 75%)` }}
      speed={-0.2}
      offset={1.1}
      factor={factor}
    />
    <Content speed={0.4} offset={offset + 0.2} factor={factor}>
      {/* <Inner>
        <div
          sx={{
            display: `grid`,
            gridGap: [4, 4, 4, 5],
            gridTemplateColumns: [`1fr`, `1fr`, `repeat(2, 1fr)`],
            h2: { gridColumn: `-1/1` },
          }}
        >
          <ProjectsMDX />
        </div>
      </Inner> */}
      <Inner>
        <Row>
          <Flex
            paddingY={2}
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              flex: `0 1 auto`
            }}>
            <Flex sx={{
              background: `url(brush.png)`,
              backgroundSize: `597px 85px`,
              '@media only screen and (min-device-width: 375px) and (max-device-width: 667px)': {
                backgroundSize: `100%`,
              },
              backgroundRepeat: `no-repeat`,
              backgroundPosition: 'center',
              width: `100%`,
            }}>
              <Heading as="h1" sx={{
                padding: `7vh 0px`,
                textAlign: `center`,
                width: `100%`,
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '40px',
                lineHeight: '48px',
                letterSpacing: '-0.2px',
                color: '#575757',
              }}>Fitness App Prototype</Heading>
            </Flex>
            <Row>
              <Text sx={{
                paddingBottom: '40px',
                paddingLeft: '20%',
                paddingRight: '20%',
                '@media only screen and (min-device-width: 375px) and (max-device-width: 667px)': {
                  paddingLeft: '0px',
                  paddingRight: '0px',
                },
                fontSize: '18px',
                lineHeight: '21px',
                letterSpacing: '-0.2px',
                color: '#575757',
              }}>
                Voluptate proident tempor ipsum in. Culpa ullamco et sit aute ullamco deserunt Lorem non dolor mollit adipisicing laboris eiusmod. Ut mollit voluptate qui cupidatat quis excepteur adipisicing deserunt elit est laborum amet.
              </Text>
            </Row>
            <Flex sx={{
              width: '100%',
              alignContent: 'flex-start',
            }}>
              <Link
                href="https://docs.google.com/presentation/d/1NStX_A_npYpf646GxtWe3nGXkwDcistjcSyFxKTEIFQ/edit?usp=sharing"
                target="_blank"
                rel="noreferrer noopener"
                sx={{
                  width: '100%',
                  textDecoration: 'none',
                  color: '#575757',
                  paddingLeft: '20%',
                  paddingRight: '20%',
                  '@media only screen and (min-device-width: 375px) and (max-device-width: 667px)': {
                    paddingLeft: '0px',
                    paddingRight: '0px',
                  },
                }}>
                <Row justifyContent="flex-start">
                  <Text
                    paddingRight={2}
                    sx={{
                      fontSize: '20px',
                      lineHeight: '24px',
                      letterSpacing: '-0.2px',
                      color: '#575757',
                    }}>
                    full case study
                  </Text>
                  <ArrowRight />
                </Row>
              </Link>
            </Flex>
          </Flex>
          <Flex
            paddingY={2}
            sx={{
              alignContent: `center`,
              alignItems: `center`,
              justifyContent: `center`,
              flex: `0 1 auto`,
              width: `100%`,
            }}>
            <Image
              sx={{
                height: `40vw`
              }}
              src="/Gold.png" />
          </Flex>
        </Row>
      </Inner>
    </Content>
    <Divider speed={0.1} offset={offset} factor={factor}>
    </Divider>
  </div>
)

export default Projects
