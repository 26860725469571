import React from "react"
import { Parallax } from "react-spring/renderprops-addons.cjs"
import { NavLink, Flex } from 'theme-ui'
import Layout from "../components/layout"
import Hero from "../components/hero"
import Projects from "../components/projects"
import About from "../components/about"
import Contact from "../components/contact"

const Cara = () => (
  <Layout>
    <Flex as='nav'>
      <NavLink
        href='#'
        paddingX={3}
        paddingY={2}
        sx={{
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '22px',
          lineHeight: '26px',
          letterSpacing: '-0.2px',
          fontFeatureSettings: `'kern' off`,
          color: '#575757',
        }}>
        brittanycoffey.
      </NavLink>
    </Flex>
    <Parallax pages={5}>
      <Hero offset={0} factor={1} />
      <Projects offset={1} factor={2} />
      <About offset={3} factor={1} />
      <Contact offset={4} factor={1} />
    </Parallax>
  </Layout>
)

export default Cara
