/** @jsx jsx */
import { jsx, Grid, Image, Heading, Flex } from "theme-ui"
import Row from './Row'
import Divider from "../elements/divider"
import Inner from "../elements/inner"
import Content from "../elements/content"
import SVG from "./svg"
import { UpDown, UpDownWide } from "../styles/animations"
// @ts-ignore
import Intro from "../sections/intro"

const Hero = ({ offset, factor = 1 }: { offset: number; factor?: number }) => (
  <div>
    <Divider speed={0.2} offset={offset} factor={factor}>
    </Divider>
    <Content sx={{ variant: `texts.bigger` }} speed={0.4} offset={offset} factor={factor}>
      <Inner>
        {/* <Intro /> */}
        <Row>
          <Flex sx={{
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
            width: `100%`,
          }}>
            <Heading sx={{
              fontStyle: `normal`,
              fontWeight: `600`,
              fontSize: `25pt`,
              lineHeight: `48px`,
              letterSpacing: `-0.2px`,
              color: `#575757`,
              width: `100%`,
            }}>Brittany Coffey is a UX Designer in Chattanooga, TN.</Heading>
          </Flex>
          <Flex sx={{
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
            width: `100%`,
          }}>
            <Image
              sx={{
                height: `40vw`,
              }}
              src="/Profile Photo.png" />
          </Flex>
        </Row>
      </Inner>
    </Content>
  </div>
)

export default Hero
